import Color from 'color';
import qs from 'qs';

import { AddressFields } from './types';

/**
 * @return RGB color.
 */
export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result?.slice(1, 4).map((value) => parseInt(value, 16)).join(',') || '';
}

/**
 * @return HEX color.
 */
export function getAccessibleColor(value: string) {
  const color = Color(value);
  const rgb = color.rgb();
  const [r, g, b] = [rgb.red(), rgb.green(), rgb.blue()];
  // Brightness calculation based on W3C standard: https://www.w3.org/TR/AERT/#color-contrast
  // The green factor is lowered from 587 to reduce bias towards dark complimentary colors for yellows and greens.
  const brightness = (r * 299 + g * 520 + b * 114) / 1000;
  return brightness >= 128 ? color.mix(new Color('#000000'), 0.8).hex() : '#ffffff';
}

/**
 * @param amount A number between 0 and 1
 * @return Hex color.
 */
export function darkenColor(value: string, amount: number) {
  return Color(value).darken(amount).hex();
}

/**
 * @param amount A number between 0 and 1
 * @return Hex color.
 */
export function lightenColor(value: string, amount: number) {
  return Color(value).lighten(amount).hex();
}

/**
 * @param amount A number between 0 and 1
 * @return RGB color.
 */
export function transparentizeColor(value: string, amount: number) {
  return Color(value).fade(amount).toString();
}

/**
 * Strips http(s)://, www., trailing slash, and query parameters from a URL.
 */
export function prettifyUrl(url: string) {
  return url.replace(/^https?:\/\//i, '').replace(/^www\./i, '').split('?')[0].replace(/\/$/, '');
}

export function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function containsAddress(data: Partial<AddressFields>) {
  return !!data.street
      || !!data.house_number
      || !!data.extra_address_line
      || !!data.zip_code
      || !!data.city;
}

export function encodeUrl(params: object) {
  return qs.stringify(params, { encodeValuesOnly: true, skipNulls: true });
}

export function url(path: string, params: object | null) {
  const root = window.location.origin;

  const url = `${root}/${path.replace(/^\//, '')}`;

  if (params) {
    return query(url, params);
  }

  return url;
}

export function query(path: string, params: object) {
  const query = encodeUrl(params);

  return query ? `${path}?${query}` : path;
}
